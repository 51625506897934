.mobile-nav-bar__container {
  position: fixed;
  display: none;
  top: 0;

  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  z-index: 999999;

  background-color: white;
  border-color: #0f172a1a;
  border-bottom-width: 1px;
}

.mobile-nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* responsive */

  height: 4rem;
  max-width: 1200px;

  padding: 0.8rem 1.6rem;
  margin: 0;
}

.mobile-nav-bar__brand {
  flex: 1;
  display: flex;
  align-items: center;

  height: 100%;

  /* responsive */

  margin-right: 1.6rem;
  overflow: hidden;
}

.mobile-nav-bar__link {
  display: flex;
  align-items: center;

  height: 100%;
}

.mobile-nav-bar__logo {
  height: 2.4rem;
}

.mobile-nav-bar__menu {
  width: 100%;
  height: calc(100% - 4rem);
  position: fixed;
  left: 0;
  top: 4rem;
  background-color: white;

  z-index: 999999;
}

.mobile-nav-bar__toggle {
  margin-left: 2.4rem;
  font-size: 3.6rem;
  cursor: pointer;
}

.mobile-nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
}

.mobile-nav-bar__tab {
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/

  font-weight: 500;
  font-size: 2rem;
  line-height: 16px;

  padding: 3.2rem 2.4rem;
  background-color: white;
  color: #000;
  width: 100%;
}

.mobile-nav-bar__tab:last-child {
  margin-right: 0;
}

.mobile-nav-bar__tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--indigo);

  /* responsive */

  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.mobile-nav-bar__tab:hover {
  color: var(--white);
}

.mobile-nav-bar__icon {
  display: none;
}

.mobile-nav-bar__buttons {
  display: flex;
  justify-content: center;
  padding: 3.2rem;
  background-color: white;
}

@media only screen and (max-width: 768px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: #000;
  }

  .mobile-nav-bar__tab--active:hover {
    color: #000;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-nav-bar__container {
    display: flex;
  }

  .mobile-nav-bar__menu--closed {
    display: none;
  }

  .main-page-content {
    margin-top: 4rem;
  }
}
